nav{
    /* display: flex; */
    /* width: calc(100vw - 32px*2); */
    width: 36px;
    height: 100vh;
    justify-content: space-between;
    /* margin: 8px 2px; */
    position: fixed;
    right: 0px;
    z-index: 999;
    background-color: var(--black-color);
    
    
}

nav>ul{
    padding-top: 36px;
    
}

nav>ul>li{
    padding: 8px 8px;
    list-style: none;
    font-size: 22px;
    transition: 0.5s;
    width: 100px;
    /* transform: rotate(5deg); */
    transform: skewX(-5deg);
}
nav>div>h1{
    /* transform: skewX(5deg); */
    padding-top: 8px;
    padding-bottom: 8px;
    
}
nav>ul>li>a{
    color: var(--primary-color);
    transition: 0.5s;
}
nav>ul>li>a>*{
    transform: skewX(5deg);
    
}
.nav-name{
    padding-right: 100px;
    transform: translateX(-4px);
    transition: 0.5s;
    padding-left: 8px;
}
.nav-name>h1>span{
    
    transition: 0.5s;
}
.nav-name>h1>span:hover{
    
    padding: 16px 0px ;
    animation: rainbow-text 0.5s linear infinite reverse;
}
.nav-link>li:hover{
    background-color: var(--secondary-color);
    transform: translateX(-10px) skewX(-5deg);
    
}
.social-link>li>a:hover{
    color: var(--secondary-color);
}
.vertical-text{
    writing-mode: vertical-lr;
    color: var(--primary-color);
    margin: 0;
}


