@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

:root {
    

    --primary-color: rgb(255, 255, 255);
    --secondary-color: rgb(255, 30, 0);
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;
    --bg-main: rgb(29, 31, 36);
    --bg-sub: rgb(0, 0, 0);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: 'Ubuntu', sans-serif;
    cursor: crosshair;
}

html{
    /* background-color: var(--bg-main);   */
    background-image: linear-gradient(var(--bg-main)80%,var(--bg-sub));  
    overflow-x: hidden;
    
}
body{
    overflow-x: hidden !important;
}

a{
    text-decoration: none;
    cursor: pointer;
}

.glitch {
    color: white;
    /* font-size: 100px; */
    position: relative;
    /* width: 400px; */
    /* margin: 0 auto; */
    
    
}
.glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 4px 0 blue;
    top: 0;
    color: white;
    background: var(--black-color);
    opacity: 0.5;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    transform: translateY(10px);
}
.glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -4px 0 red;
    top: 0;
    color: white;
    background: var(--black-color);
    opacity: 0.5;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    transform: translateY(15px);
}
@keyframes rainbow-text {
  0% {color: violet;}
  16.5% {color: indigo;}
  33% {color: blue;}
  48.5% {color: green;}
  66% {color: yellow;}
  80.5% {color: orange;}
  100% {color: red;}
}
@keyframes rainbow-border {
  0% {border-color: violet;}
  16.5% {border-color: indigo;}
  33% {border-color: blue;}
  48.5% {border-color: green;}
  66% {border-color: yellow;}
  80.5% {border-color: orange;}
  100% {border-color: red;}
}
@keyframes noise-anim {
    0% {
      clip: rect(88px, 9999px, 98px, 0);
    }
    5% {
      clip: rect(39px, 9999px, 2px, 0);
    }
    10% {
      clip: rect(11px, 9999px, 17px, 0);
    }
    15% {
      clip: rect(37px, 9999px, 22px, 0);
    }
    20% {
      clip: rect(85px, 9999px, 2px, 0);
    }
    25% {
      clip: rect(45px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(62px, 9999px, 36px, 0);
    }
    35% {
      clip: rect(77px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(79px, 9999px, 81px, 0);
    }
    45% {
      clip: rect(51px, 9999px, 82px, 0);
    }
    50% {
      clip: rect(72px, 9999px, 61px, 0);
    }
    55% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    60% {
      clip: rect(46px, 9999px, 19px, 0);
    }
    65% {
      clip: rect(56px, 9999px, 57px, 0);
    }
    70% {
      clip: rect(19px, 9999px, 81px, 0);
    }
    75% {
      clip: rect(48px, 9999px, 44px, 0);
    }
    80% {
      clip: rect(14px, 9999px, 93px, 0);
    }
    85% {
      clip: rect(24px, 9999px, 48px, 0);
    }
    90% {
      clip: rect(2px, 9999px, 47px, 0);
    }
    95% {
      clip: rect(87px, 9999px, 100px, 0);
    }
    100% {
      clip: rect(50px, 9999px, 59px, 0);
    }
  }
   
  @keyframes noise-anim-2 {
    0% {
      clip: rect(95px, 9999px, 2px, 0);
    }
    5% {
      clip: rect(95px, 9999px, 92px, 0);
    }
    10% {
      clip: rect(19px, 9999px, 86px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 31px, 0);
    }
    20% {
      clip: rect(70px, 9999px, 4px, 0);
    }
    25% {
      clip: rect(29px, 9999px, 55px, 0);
    }
    30% {
      clip: rect(70px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(1px, 9999px, 20px, 0);
    }
    40% {
      clip: rect(48px, 9999px, 52px, 0);
    }
    45% {
      clip: rect(53px, 9999px, 18px, 0);
    }
    50% {
      clip: rect(1px, 9999px, 28px, 0);
    }
    55% {
      clip: rect(40px, 9999px, 54px, 0);
    }
    60% {
      clip: rect(62px, 9999px, 60px, 0);
    }
    65% {
      clip: rect(94px, 9999px, 36px, 0);
    }
    70% {
      clip: rect(79px, 9999px, 16px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 5px, 0);
    }
    80% {
      clip: rect(63px, 9999px, 22px, 0);
    }
    85% {
      clip: rect(36px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(38px, 9999px, 69px, 0);
    }
    95% {
      clip: rect(38px, 9999px, 91px, 0);
    }
    100% {
      clip: rect(18px, 9999px, 48px, 0);
    }
}   