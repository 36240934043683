.home{
    height: 100vh;
    width: calc(100vw - 64px);
    transform: translateX(32px);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.circles{
    width: 100vw;
    height: 200%;
    overflow: hidden;
    position: absolute;
    
}
.circle-1,
.circle-2,
.circle-3,
.circle-4,
.circle-5,
.circle-6{
    position: absolute;
}
.circle-1{
    background-color: rgba(0, 255, 255, 0.082);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    left: 80px;
    top: 160px;
}
.circle-2{
    background-color: rgba(0, 141, 151, 0.411);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    top: 280px;
    left: 200px;
}
.circle-3{
    background-color: rgba(73, 151, 0, 0.116);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 480px;
    left: 300px;
}
.circle-6{
    background-color: rgba(0, 108, 197, 0.274);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    top: 480px;
    left: 70vw;
}
.circle-5{
    background-color: rgba(146, 199, 0, 0.11);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: 480px;
    left: 65vw;
}
.circle-4{
    background-color: rgba(0, 183, 207, 0.212);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: 80px;
    left: 85vw;
}
.home-content{
    width: 600px;
    /* background-color: green; */
    position: relative;
    /* left: 20px; */
    z-index: 20;
    
    

}
.home-content>div>h1,.home-content>div>h2{
    color: var(--primary-color);
    padding: 16px 0px;
}

.home-content>div>h1{
    font-size: 55px;
}
.home-name{
    border-left: 4px solid var(--gray-color);
    padding-left: 16px;
    
}
.home-name:hover{
    animation: rainbow-border 0.5s linear infinite;
}


.home-btn{
    height: 40px;
    transform: translateY(32px);
    display: flex;
    
}
.home-btn>div{
    padding: 8px 16px;
    /* background-color: var(--secondary-color); */
    border: 2px solid var(--secondary-color);
    margin: 0px 2px;
    height: 40px;
    transform: skewX(-5deg);
    transition: 0.5s;
}
.home-btn>div:hover{
    transform: skewX(-5deg) translateY(-4px);
    background-color: var(--secondary-color);
}
.home-btn>div>a{
    font-size: 22px;
    color: var(--primary-color);
}



/* The laptop with borders */
.laptop-container{
    width: 800px;
    height: 400px;
    /* background-color: red; */
    z-index: 10;
    /* display: none; */
    /* align-items: center; */
    padding-top: 36px;
    /* position: sticky; */
    z-index: 10;
}

.laptop{
    border: 20px solid black;
    border-radius: 5px;
    width: 700px;
    height: 400px;
    position: absolute;
}
.laptop:before{
    display: block;
    border-radius: 50%;
    content: '';
    width: 10px;
    height: 10px;
    background-color: rgb(139, 139, 139);
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(50%,-14px);
}
.laptop:after{
    display: block;
    content: '';
    background-color: black;
    width: 750px;
    height: 20px;
    transform: translate(-45px,100px);
    position: absolute;
    bottom: 55px;
}
.content{
    background-color: rgb(213, 224, 228);
    height: 360px;
}

iframe{
    width: 100%;
    height: 100%;
    border: none;
}

@media only screen and (max-width: 1463px){
    .laptop-container{
        transform: scale(0.7);
    }
}
.home-name>h1>span{
    cursor: crosshair;
    transition: 0.2s;
    position: relative;
}
.home-name>h1>span:hover{
    /* color: red; */
    padding: 16px;
    animation: rainbow-text 0.5s linear infinite reverse;
    
}



