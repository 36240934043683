.footer-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}
.footer-container>h2,.form-after-text{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
}
.footer-container>h2>span{
    color: var(--secondary-color);
    text-align: right;
    display: block;
}

.contact-link{
    display: flex;
    width: 95vw;
    justify-content: center;
    flex-wrap: wrap;
    
}
.contact-link>div{
    padding: 16px;
    transform: skewX(-5deg);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact-link>div>a{
    padding-left: 8px;
    font-size: 20px;
    color: var(--secondary-color);
}
.form-container{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 364px;
    padding-top: 32px;
    
}
.form-container>*{
    padding: 2px;
    transition: 0.2s;
}

.form-container>div>input,.form-container>div>textarea{
    width: 300px;
    height: 40px;
    transform: skewX(-5deg);
    border: 0;
    margin: 2px;
    padding: 2px;
    transition: 0.2s;
}
.form-container>div>input:hover,.form-container>div>textarea:hover{
    background-color: antiquewhite;
    transform: translateY(-4px) skewX(-5deg);
}
.form-container>div>textarea{
    
    height: 80px;
}
.form-container>button{
    background-color: greenyellow;
    width: 300px;
    height: 40px;
    transform: skewX(-5deg);
}
.form-container>button:hover{
    transform: translateY(-4px) skewX(-5deg);
    background-color: green;
    color: white;
}
.footer-img{
    padding-top: 32px;
    max-height: 300px;
    width: 100vw;
    overflow: hidden;
}
.footer-img>img{
    filter: opacity(0.4);
    
}