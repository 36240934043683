@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
:root {
    

    --primary-color: rgb(255, 255, 255);
    --secondary-color: rgb(255, 30, 0);
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #6b7688;
    --brown-color: #46364a;
    --white-color: #ffffff;
    --bg-main: rgb(29, 31, 36);
    --bg-sub: rgb(0, 0, 0);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: 'Ubuntu', sans-serif;
    cursor: crosshair;
}

html{
    /* background-color: var(--bg-main);   */
    background-image: linear-gradient(rgb(29, 31, 36)80%,rgb(0, 0, 0));
    background-image: linear-gradient(var(--bg-main)80%,var(--bg-sub));  
    overflow-x: hidden;
    
}
body{
    overflow-x: hidden !important;
}

a{
    text-decoration: none;
    cursor: pointer;
}

.glitch {
    color: white;
    /* font-size: 100px; */
    position: relative;
    /* width: 400px; */
    /* margin: 0 auto; */
    
    
}
.glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 4px 0 blue;
    top: 0;
    color: white;
    background: #030303;
    background: var(--black-color);
    opacity: 0.5;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
}
.glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -4px 0 red;
    top: 0;
    color: white;
    background: #030303;
    background: var(--black-color);
    opacity: 0.5;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
}
@-webkit-keyframes rainbow-text {
  0% {color: violet;}
  16.5% {color: indigo;}
  33% {color: blue;}
  48.5% {color: green;}
  66% {color: yellow;}
  80.5% {color: orange;}
  100% {color: red;}
}
@keyframes rainbow-text {
  0% {color: violet;}
  16.5% {color: indigo;}
  33% {color: blue;}
  48.5% {color: green;}
  66% {color: yellow;}
  80.5% {color: orange;}
  100% {color: red;}
}
@-webkit-keyframes rainbow-border {
  0% {border-color: violet;}
  16.5% {border-color: indigo;}
  33% {border-color: blue;}
  48.5% {border-color: green;}
  66% {border-color: yellow;}
  80.5% {border-color: orange;}
  100% {border-color: red;}
}
@keyframes rainbow-border {
  0% {border-color: violet;}
  16.5% {border-color: indigo;}
  33% {border-color: blue;}
  48.5% {border-color: green;}
  66% {border-color: yellow;}
  80.5% {border-color: orange;}
  100% {border-color: red;}
}
@-webkit-keyframes noise-anim {
    0% {
      clip: rect(88px, 9999px, 98px, 0);
    }
    5% {
      clip: rect(39px, 9999px, 2px, 0);
    }
    10% {
      clip: rect(11px, 9999px, 17px, 0);
    }
    15% {
      clip: rect(37px, 9999px, 22px, 0);
    }
    20% {
      clip: rect(85px, 9999px, 2px, 0);
    }
    25% {
      clip: rect(45px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(62px, 9999px, 36px, 0);
    }
    35% {
      clip: rect(77px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(79px, 9999px, 81px, 0);
    }
    45% {
      clip: rect(51px, 9999px, 82px, 0);
    }
    50% {
      clip: rect(72px, 9999px, 61px, 0);
    }
    55% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    60% {
      clip: rect(46px, 9999px, 19px, 0);
    }
    65% {
      clip: rect(56px, 9999px, 57px, 0);
    }
    70% {
      clip: rect(19px, 9999px, 81px, 0);
    }
    75% {
      clip: rect(48px, 9999px, 44px, 0);
    }
    80% {
      clip: rect(14px, 9999px, 93px, 0);
    }
    85% {
      clip: rect(24px, 9999px, 48px, 0);
    }
    90% {
      clip: rect(2px, 9999px, 47px, 0);
    }
    95% {
      clip: rect(87px, 9999px, 100px, 0);
    }
    100% {
      clip: rect(50px, 9999px, 59px, 0);
    }
  }
@keyframes noise-anim {
    0% {
      clip: rect(88px, 9999px, 98px, 0);
    }
    5% {
      clip: rect(39px, 9999px, 2px, 0);
    }
    10% {
      clip: rect(11px, 9999px, 17px, 0);
    }
    15% {
      clip: rect(37px, 9999px, 22px, 0);
    }
    20% {
      clip: rect(85px, 9999px, 2px, 0);
    }
    25% {
      clip: rect(45px, 9999px, 15px, 0);
    }
    30% {
      clip: rect(62px, 9999px, 36px, 0);
    }
    35% {
      clip: rect(77px, 9999px, 36px, 0);
    }
    40% {
      clip: rect(79px, 9999px, 81px, 0);
    }
    45% {
      clip: rect(51px, 9999px, 82px, 0);
    }
    50% {
      clip: rect(72px, 9999px, 61px, 0);
    }
    55% {
      clip: rect(49px, 9999px, 68px, 0);
    }
    60% {
      clip: rect(46px, 9999px, 19px, 0);
    }
    65% {
      clip: rect(56px, 9999px, 57px, 0);
    }
    70% {
      clip: rect(19px, 9999px, 81px, 0);
    }
    75% {
      clip: rect(48px, 9999px, 44px, 0);
    }
    80% {
      clip: rect(14px, 9999px, 93px, 0);
    }
    85% {
      clip: rect(24px, 9999px, 48px, 0);
    }
    90% {
      clip: rect(2px, 9999px, 47px, 0);
    }
    95% {
      clip: rect(87px, 9999px, 100px, 0);
    }
    100% {
      clip: rect(50px, 9999px, 59px, 0);
    }
  }
   
  @-webkit-keyframes noise-anim-2 {
    0% {
      clip: rect(95px, 9999px, 2px, 0);
    }
    5% {
      clip: rect(95px, 9999px, 92px, 0);
    }
    10% {
      clip: rect(19px, 9999px, 86px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 31px, 0);
    }
    20% {
      clip: rect(70px, 9999px, 4px, 0);
    }
    25% {
      clip: rect(29px, 9999px, 55px, 0);
    }
    30% {
      clip: rect(70px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(1px, 9999px, 20px, 0);
    }
    40% {
      clip: rect(48px, 9999px, 52px, 0);
    }
    45% {
      clip: rect(53px, 9999px, 18px, 0);
    }
    50% {
      clip: rect(1px, 9999px, 28px, 0);
    }
    55% {
      clip: rect(40px, 9999px, 54px, 0);
    }
    60% {
      clip: rect(62px, 9999px, 60px, 0);
    }
    65% {
      clip: rect(94px, 9999px, 36px, 0);
    }
    70% {
      clip: rect(79px, 9999px, 16px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 5px, 0);
    }
    80% {
      clip: rect(63px, 9999px, 22px, 0);
    }
    85% {
      clip: rect(36px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(38px, 9999px, 69px, 0);
    }
    95% {
      clip: rect(38px, 9999px, 91px, 0);
    }
    100% {
      clip: rect(18px, 9999px, 48px, 0);
    }
}
   
  @keyframes noise-anim-2 {
    0% {
      clip: rect(95px, 9999px, 2px, 0);
    }
    5% {
      clip: rect(95px, 9999px, 92px, 0);
    }
    10% {
      clip: rect(19px, 9999px, 86px, 0);
    }
    15% {
      clip: rect(3px, 9999px, 31px, 0);
    }
    20% {
      clip: rect(70px, 9999px, 4px, 0);
    }
    25% {
      clip: rect(29px, 9999px, 55px, 0);
    }
    30% {
      clip: rect(70px, 9999px, 80px, 0);
    }
    35% {
      clip: rect(1px, 9999px, 20px, 0);
    }
    40% {
      clip: rect(48px, 9999px, 52px, 0);
    }
    45% {
      clip: rect(53px, 9999px, 18px, 0);
    }
    50% {
      clip: rect(1px, 9999px, 28px, 0);
    }
    55% {
      clip: rect(40px, 9999px, 54px, 0);
    }
    60% {
      clip: rect(62px, 9999px, 60px, 0);
    }
    65% {
      clip: rect(94px, 9999px, 36px, 0);
    }
    70% {
      clip: rect(79px, 9999px, 16px, 0);
    }
    75% {
      clip: rect(66px, 9999px, 5px, 0);
    }
    80% {
      clip: rect(63px, 9999px, 22px, 0);
    }
    85% {
      clip: rect(36px, 9999px, 57px, 0);
    }
    90% {
      clip: rect(38px, 9999px, 69px, 0);
    }
    95% {
      clip: rect(38px, 9999px, 91px, 0);
    }
    100% {
      clip: rect(18px, 9999px, 48px, 0);
    }
}   
.about-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 100px; */
}
.about-container>h2{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
    

}
.about-container>h2>span{
    color: var(--secondary-color);
    text-align: right;
    display: block;
    
}
.about-cards{
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    /* background-color: red; */
}
.about-cards>div{
    width: 300px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    margin: 8px;
    margin-bottom: 32px;
    transition: 0.5s;
}
.about-cards>div>img{
    border-bottom: 2px groove green;
    /* border-left: 10px groove var(--secondary-color); */
}
.about-cards>div>h2{
    color: var(--primary-color);
    width: 290px;
    padding-left: 4px;
}
.about-cards>div>p{
    text-align: justify;
    color: var(--primary-color);
    width: 290px;
    padding-left: 4px;
    padding-bottom: 4px;
}
.about-cards>div:hover{
    -webkit-transform: skewX(-5deg) translateY(-8px);
            transform: skewX(-5deg) translateY(-8px);
    background-color: rgb(20, 20, 20);
    box-shadow: 8px 8px green;
}
.footer-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}
.footer-container>h2,.form-after-text{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
}
.footer-container>h2>span{
    color: var(--secondary-color);
    text-align: right;
    display: block;
}

.contact-link{
    display: flex;
    width: 95vw;
    justify-content: center;
    flex-wrap: wrap;
    
}
.contact-link>div{
    padding: 16px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact-link>div>a{
    padding-left: 8px;
    font-size: 20px;
    color: var(--secondary-color);
}
.form-container{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 364px;
    padding-top: 32px;
    
}
.form-container>*{
    padding: 2px;
    transition: 0.2s;
}

.form-container>div>input,.form-container>div>textarea{
    width: 300px;
    height: 40px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    border: 0;
    margin: 2px;
    padding: 2px;
    transition: 0.2s;
}
.form-container>div>input:hover,.form-container>div>textarea:hover{
    background-color: antiquewhite;
    -webkit-transform: translateY(-4px) skewX(-5deg);
            transform: translateY(-4px) skewX(-5deg);
}
.form-container>div>textarea{
    
    height: 80px;
}
.form-container>button{
    background-color: greenyellow;
    width: 300px;
    height: 40px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
}
.form-container>button:hover{
    -webkit-transform: translateY(-4px) skewX(-5deg);
            transform: translateY(-4px) skewX(-5deg);
    background-color: green;
    color: white;
}
.footer-img{
    padding-top: 32px;
    max-height: 300px;
    width: 100vw;
    overflow: hidden;
}
.footer-img>img{
    -webkit-filter: opacity(0.4);
            filter: opacity(0.4);
    
}
.home{
    height: 100vh;
    width: calc(100vw - 64px);
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.circles{
    width: 100vw;
    height: 200%;
    overflow: hidden;
    position: absolute;
    
}
.circle-1,
.circle-2,
.circle-3,
.circle-4,
.circle-5,
.circle-6{
    position: absolute;
}
.circle-1{
    background-color: rgba(0, 255, 255, 0.082);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    left: 80px;
    top: 160px;
}
.circle-2{
    background-color: rgba(0, 141, 151, 0.411);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    top: 280px;
    left: 200px;
}
.circle-3{
    background-color: rgba(73, 151, 0, 0.116);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: 480px;
    left: 300px;
}
.circle-6{
    background-color: rgba(0, 108, 197, 0.274);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    top: 480px;
    left: 70vw;
}
.circle-5{
    background-color: rgba(146, 199, 0, 0.11);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: 480px;
    left: 65vw;
}
.circle-4{
    background-color: rgba(0, 183, 207, 0.212);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: 80px;
    left: 85vw;
}
.home-content{
    width: 600px;
    /* background-color: green; */
    position: relative;
    /* left: 20px; */
    z-index: 20;
    
    

}
.home-content>div>h1,.home-content>div>h2{
    color: var(--primary-color);
    padding: 16px 0px;
}

.home-content>div>h1{
    font-size: 55px;
}
.home-name{
    border-left: 4px solid var(--gray-color);
    padding-left: 16px;
    
}
.home-name:hover{
    -webkit-animation: rainbow-border 0.5s linear infinite;
            animation: rainbow-border 0.5s linear infinite;
}


.home-btn{
    height: 40px;
    -webkit-transform: translateY(32px);
            transform: translateY(32px);
    display: flex;
    
}
.home-btn>div{
    padding: 8px 16px;
    /* background-color: var(--secondary-color); */
    border: 2px solid var(--secondary-color);
    margin: 0px 2px;
    height: 40px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    transition: 0.5s;
}
.home-btn>div:hover{
    -webkit-transform: skewX(-5deg) translateY(-4px);
            transform: skewX(-5deg) translateY(-4px);
    background-color: var(--secondary-color);
}
.home-btn>div>a{
    font-size: 22px;
    color: var(--primary-color);
}



/* The laptop with borders */
.laptop-container{
    width: 800px;
    height: 400px;
    /* background-color: red; */
    z-index: 10;
    /* display: none; */
    /* align-items: center; */
    padding-top: 36px;
    /* position: sticky; */
    z-index: 10;
}

.laptop{
    border: 20px solid black;
    border-radius: 5px;
    width: 700px;
    height: 400px;
    position: absolute;
}
.laptop:before{
    display: block;
    border-radius: 50%;
    content: '';
    width: 10px;
    height: 10px;
    background-color: rgb(139, 139, 139);
    z-index: 1;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(50%,-14px);
            transform: translate(50%,-14px);
}
.laptop:after{
    display: block;
    content: '';
    background-color: black;
    width: 750px;
    height: 20px;
    -webkit-transform: translate(-45px,100px);
            transform: translate(-45px,100px);
    position: absolute;
    bottom: 55px;
}
.content{
    background-color: rgb(213, 224, 228);
    height: 360px;
}

iframe{
    width: 100%;
    height: 100%;
    border: none;
}

@media only screen and (max-width: 1463px){
    .laptop-container{
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
}
.home-name>h1>span{
    cursor: crosshair;
    transition: 0.2s;
    position: relative;
}
.home-name>h1>span:hover{
    /* color: red; */
    padding: 16px;
    animation: rainbow-text 0.5s linear infinite reverse;
    
}




.skill-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}

.skill-container>h2{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
}

.skill-flex{
    display: flex;
    width: 90vw;
    justify-content: space-around;
    flex-wrap: wrap;
    
}
.skill-icon-container{
    width: 600px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 32px;
}
.skill-icon{
    padding: 8px;
    margin: 4px;
    transition: 0.2s;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    
}
.skill-icon:hover{
    -webkit-transform: skewX(-5deg) translateY(-4px);
            transform: skewX(-5deg) translateY(-4px);
    /* margin: 20px; */
    background-color: rgb(20, 20, 20);
    box-shadow: 2px 2px green;
}

.exp-container{
    width: 600px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.exp-container>div{
    display: flex;
    padding: 8px;
    /* width: 600px; */
    border-left: 2px solid red;
}
.exp-container>div>div{
    padding: 16px 16px;
}
.exp-container>div>div>div{
    padding-bottom: 16px;
}
.exp-container>div>div>p,.exp-container>div>div>div>h4{
    color: var(--primary-color);
    font-weight: bolder;
}
.exp-container>div>div>div>p{
    color: rgb(148, 148, 148);
}
.time-line::after{
    content: '\23BA\23BA\23BA\23BA\23BA\23BA\23BA\23BA';
    /* background-color: red; */
    color: red;
    position: relative;
    left: -26px;
}

.work-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}
.work-container>h2{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
}
.app-work-filter{
    width: calc(100vw - 64px);
    display: flex;
    padding-bottom: 36px;
    flex-wrap: wrap;
    justify-content: center;
}
.app-work-filter>div{
    color: var(--primary-color);
    padding: 8px 16px;
    /* background-color: var(--secondary-color); */
    border: 2px solid var(--secondary-color);
    margin: 2px 2px;
    height: 40px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    transition: 0.5s;
}
.item-active{
    -webkit-transform: skewX(-5deg) translateY(-4px) !important;
            transform: skewX(-5deg) translateY(-4px) !important;
    background-color: var(--secondary-color);
}
.work-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95vw;
}
.work-card{
    width: 300px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    transition: 0.5s;
    margin: 16px;
}
.work-card:hover{
    -webkit-transform: skewX(-5deg) translateY(-8px) !important;
            transform: skewX(-5deg) translateY(-8px) !important;
    background-color: rgb(20, 20, 20);
    box-shadow: 8px 8px green;
}
.work-card>div>div>a{
    color: var(--primary-color);
    padding: 4px 8px;
    /* background-color: var(--secondary-color); */
    border: 2px solid var(--secondary-color);
    margin: 2px 2px;
    height: 40px;
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
    transition: 0.5s;
}
.work-card>div>div>a:hover{
    -webkit-transform: skewX(-5deg) translateY(-4px) !important;
            transform: skewX(-5deg) translateY(-4px) !important;
    background-color: var(--secondary-color);
}
.work-card>div>div>h2{
    padding: 8px 4px;
    color: var(--primary-color);
}
.work-card>div>div>h2>span{
    font-size: 75%;
    border-right: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    
}
.work-card>div>div>p{
    padding: 8px 4px;
    color: var(--primary-color);
}
.work-card>div>img{
    border-bottom: 2px groove green;
}

nav{
    /* display: flex; */
    /* width: calc(100vw - 32px*2); */
    width: 36px;
    height: 100vh;
    justify-content: space-between;
    /* margin: 8px 2px; */
    position: fixed;
    right: 0px;
    z-index: 999;
    background-color: var(--black-color);
    
    
}

nav>ul{
    padding-top: 36px;
    
}

nav>ul>li{
    padding: 8px 8px;
    list-style: none;
    font-size: 22px;
    transition: 0.5s;
    width: 100px;
    /* transform: rotate(5deg); */
    -webkit-transform: skewX(-5deg);
            transform: skewX(-5deg);
}
nav>div>h1{
    /* transform: skewX(5deg); */
    padding-top: 8px;
    padding-bottom: 8px;
    
}
nav>ul>li>a{
    color: var(--primary-color);
    transition: 0.5s;
}
nav>ul>li>a>*{
    -webkit-transform: skewX(5deg);
            transform: skewX(5deg);
    
}
.nav-name{
    padding-right: 100px;
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
    transition: 0.5s;
    padding-left: 8px;
}
.nav-name>h1>span{
    
    transition: 0.5s;
}
.nav-name>h1>span:hover{
    
    padding: 16px 0px ;
    animation: rainbow-text 0.5s linear infinite reverse;
}
.nav-link>li:hover{
    background-color: var(--secondary-color);
    -webkit-transform: translateX(-10px) skewX(-5deg);
            transform: translateX(-10px) skewX(-5deg);
    
}
.social-link>li>a:hover{
    color: var(--secondary-color);
}
.vertical-text{
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    color: var(--primary-color);
    margin: 0;
}




