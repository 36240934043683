.skill-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}

.skill-container>h2{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
}

.skill-flex{
    display: flex;
    width: 90vw;
    justify-content: space-around;
    flex-wrap: wrap;
    
}
.skill-icon-container{
    width: 600px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 32px;
}
.skill-icon{
    padding: 8px;
    margin: 4px;
    transition: 0.2s;
    transform: skewX(-5deg);
    
}
.skill-icon:hover{
    transform: skewX(-5deg) translateY(-4px);
    /* margin: 20px; */
    background-color: rgb(20, 20, 20);
    box-shadow: 2px 2px green;
}

.exp-container{
    width: 600px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.exp-container>div{
    display: flex;
    padding: 8px;
    /* width: 600px; */
    border-left: 2px solid red;
}
.exp-container>div>div{
    padding: 16px 16px;
}
.exp-container>div>div>div{
    padding-bottom: 16px;
}
.exp-container>div>div>p,.exp-container>div>div>div>h4{
    color: var(--primary-color);
    font-weight: bolder;
}
.exp-container>div>div>div>p{
    color: rgb(148, 148, 148);
}
.time-line::after{
    content: '⎺⎺⎺⎺⎺⎺⎺⎺';
    /* background-color: red; */
    color: red;
    position: relative;
    left: -26px;
}