.work-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}
.work-container>h2{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
}
.app-work-filter{
    width: calc(100vw - 64px);
    display: flex;
    padding-bottom: 36px;
    flex-wrap: wrap;
    justify-content: center;
}
.app-work-filter>div{
    color: var(--primary-color);
    padding: 8px 16px;
    /* background-color: var(--secondary-color); */
    border: 2px solid var(--secondary-color);
    margin: 2px 2px;
    height: 40px;
    transform: skewX(-5deg);
    transition: 0.5s;
}
.item-active{
    transform: skewX(-5deg) translateY(-4px) !important;
    background-color: var(--secondary-color);
}
.work-card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95vw;
}
.work-card{
    width: 300px;
    transform: skewX(-5deg);
    transition: 0.5s;
    margin: 16px;
}
.work-card:hover{
    transform: skewX(-5deg) translateY(-8px) !important;
    background-color: rgb(20, 20, 20);
    box-shadow: 8px 8px green;
}
.work-card>div>div>a{
    color: var(--primary-color);
    padding: 4px 8px;
    /* background-color: var(--secondary-color); */
    border: 2px solid var(--secondary-color);
    margin: 2px 2px;
    height: 40px;
    transform: skewX(-5deg);
    transition: 0.5s;
}
.work-card>div>div>a:hover{
    transform: skewX(-5deg) translateY(-4px) !important;
    background-color: var(--secondary-color);
}
.work-card>div>div>h2{
    padding: 8px 4px;
    color: var(--primary-color);
}
.work-card>div>div>h2>span{
    font-size: 75%;
    border-right: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    
}
.work-card>div>div>p{
    padding: 8px 4px;
    color: var(--primary-color);
}
.work-card>div>img{
    border-bottom: 2px groove green;
}
