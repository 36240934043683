.about-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 100px; */
}
.about-container>h2{
    color: var(--primary-color);
    padding: 64px;
    font-size: 32px;
    

}
.about-container>h2>span{
    color: var(--secondary-color);
    text-align: right;
    display: block;
    
}
.about-cards{
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    /* background-color: red; */
}
.about-cards>div{
    width: 300px;
    transform: skewX(-5deg);
    margin: 8px;
    margin-bottom: 32px;
    transition: 0.5s;
}
.about-cards>div>img{
    border-bottom: 2px groove green;
    /* border-left: 10px groove var(--secondary-color); */
}
.about-cards>div>h2{
    color: var(--primary-color);
    width: 290px;
    padding-left: 4px;
}
.about-cards>div>p{
    text-align: justify;
    color: var(--primary-color);
    width: 290px;
    padding-left: 4px;
    padding-bottom: 4px;
}
.about-cards>div:hover{
    transform: skewX(-5deg) translateY(-8px);
    background-color: rgb(20, 20, 20);
    box-shadow: 8px 8px green;
}